<template>
  <div class="container" v-if="page">
    <div class="map" id="map"></div>

    <div class="content">
      <h1>{{ page.title }}</h1>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <p class="wptext" v-html="content(page.content_html)[0]"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    pageID: "23148"
  }),
  components: {
    Hero: () => import("@/views/components/Hero.vue")
  },
  methods: {
    content(str) {
      str = str.split("***SERVICES***");
      return str;
    }
  },
  computed: {
    page() {
      let pages = this.$store.state.pages;
      let page;

      Object.keys(pages).forEach(key => {
        if (pages[key].id.split("?p=").pop() === this.pageID) {
          page = pages[key];
        }
      });

      return page;
    }
  },
  mounted() {
    mapboxgl.accessToken = "pk.eyJ1IjoiaGVnZWJyb3duIiwiYSI6ImNrbWx5bzByZzAybW0ydXBlYW5tcW03OXgifQ.u6r3WXOZ6V2-tPLH-5iasA";
    var map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/light-v10",
      center: [10.759549641293797, 59.91857277776334],
      zoom: 14
    });

    var marker = new mapboxgl.Marker({
      color: "#D3653F",
      draggable: true
    })
      .setLngLat([10.759549641293797, 59.91857277776334])
      .addTo(map);
  }
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets/images";
.col2 {
  padding: 60px 20px;

  @media only screen and (min-width: 992px) {
    padding: 0 125px;
  }
}

.map {
  width: 100%;
  height: 300px;
  margin-bottom: 60px;

  @media only screen and (min-width: 62em) {
    height: 500px;
  }
}
</style>
